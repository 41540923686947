<template>
  <div class="videoAssignCategoryForm">
    <form v-on:submit.prevent="saveChanges">
      <div class="form-group">
        <label for="body">Video:</label>
        <select
          class="form-control"
          name="video"
          v-model="categorize.video"
        >
          <option
            v-for="video in videos"
            v-bind:value="video.id"
            v-bind:key="video.id"
          >
            {{ video.title }}
          </option>
        </select>
      </div>
      <div class="form-group">
        <label for="body">Category:</label>
        <select
          class="form-control"
          name="category"
          v-model="categorize.category"
        >
          <option
            v-for="category in categories"
            v-bind:value="category.id"
            v-bind:key="category.id"
          >
            {{ category.name }}
          </option>
        </select>
      </div>

      <div class="form-group">
        <button class="btn btn-primary">Save</button>
      </div>
    </form>
  </div>
</template>

<script>
export default {
  data() {
    return {
      categories: [],
      videos: [],
      categorize: {}
    };
  },

  methods: {
    saveChanges: function() {
      let token = this.$cookies.get("poolfit_admin_session");

      if (token !== null) {
        let options = {
          headers: { "X-Access-Token": token }
        };

        this.$http
          .post(
            process.env.VUE_APP_ROOT_API + "/videos/categorize",
            this.categorize,
            options
          )
          .then(response => {
            if (response.status === 200) {
              alert("Record created successfully");
            }
          });
      }
    }
  },

  created() {
    this.$http
      .get(process.env.VUE_APP_ROOT_API + "/videos/categories")
      .then(response => {
        this.categories = response.data;
      });

    this.$http
      .get(process.env.VUE_APP_ROOT_API + "/videos?all=1")
      .then(response => {
        this.videos = response.data;
      });
  }
};
</script>
